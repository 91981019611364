import React, { useState, useEffect, useRef } from "react";
import { Button, Drawer, Select } from "antd";
import img from "../assets/Fingenius/BNB.png";
// import img1 from "../assets/images/23.png";
import img2 from "../assets/Fingenius/ADA.png";
import img3 from "../assets/Fingenius/BNB.png";
import img4 from "../assets/Fingenius/BTC.png";
import img5 from "../assets/Fingenius/DOGE.png";
import img6 from "../assets/Fingenius/ETH.png";
import img7 from "../assets/Fingenius/GALA.png";
import img8 from "../assets/Fingenius/MANA.png";
import img9 from "../assets/Fingenius/BNB.png";
import img10 from "../assets/Fingenius/SOL.png";
import img11 from "../assets/Fingenius/XRP.png";
import img12 from "../assets/Fingenius/USDC.png";
import img13 from "../assets/Fingenius/FDUSD.png";
import img111 from "../assets/coins/BINANCEFUTURES.png";
import img211 from "../assets/coins/BINANCESPOT.png";
import img511 from "../assets/coins/BYBITFUTURES.png";
import img611 from "../assets/coins/BYBITSPOT.png";
import img311 from "../assets/coins/KUCOINFUTURES.png";
import img411 from "../assets/coins/KUCOINSPOT.png";
import axios from "axios";
import { Activebotnew } from "../Components/Activebotnew";
import { Link } from "react-router-dom";
import { FiRefreshCcw } from "react-icons/fi";


const Balance = () => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const [filter, setFilter] = useState("SPOT");
  const [USDTonload, setUSDTonload] = useState();
  const [selectedAPI, setSelectedAPI] = useState();
  const [Percentage, setPercentage] = useState(null);
  const [selectedBalance, setSelectedBalance] = useState({});
  const [totalValue, setTotalValue] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [receivedCoins, setReceivedCoins] = useState(new Set());
  const [interval, setinterval] = "0";
  const [apilistdata, setapilistdata] = useState([]);

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const coinData = [
    {
      name: `USDT`,
      fullname: `BUSDUSDT`,
      price: "0.000 ",
      info: "1.000 USDT",
    },
    {
      name: `USDC`,
      fullname: `BUSDUSDC`,
      price: "0.000 ",
      info: "1.000 USDC",
    },
    // {
    //   name: `ADA`,
    //   fullname: `ADAUSDT`,
    //   price: "0.000 ",
    //   info: "0.000 ADA",
    // },
    {
      name: "BNB",
      fullname: `BNBUSDT`,
      price: "0.000 ",
      info: "0.000 BNB",
    },
    {
      name: "BTC",
      fullname: `BTCUSDT`,
      price: "0.000 ",
      info: "0.000 BTC",
    },
    // {
    //   name: "DOGE",
    //   fullname: `DOGEUSDT`,
    //   price: "0.000",
    //   info: "0.000 DOGE",
    // },
    {
      name: "ETH",
      fullname: `ETHUSDT`,
      price: "0.000",
      info: "0.000 ETH",
    },
    {
      name: "FDUSD",
      fullname: `FDUSD`,
      price: "0.000",
      info: "0.000 FDUSD",
    },
    // {
    //   name: "GALA",
    //   fullname: `GALAUSDT`,
    //   price: "0.000",
    //   info: "0.000 GALA",
    // },
    // {
    //   name: "MANA",
    //   fullname: `MANAUSDT`,
    //   price: "0.000",
    //   info: "0.000 MANA",
    // },

    // {
    //   name: "OCEAN",
    //   fullname: `OCEANUSDT`,
    //   price: "0.000 ",
    //   info: "0.000 OCEAN",
    // },
    // {
    //   name: "SOL",
    //   fullname: `SOLUSDT`,
    //   price: "0.000 ",
    //   info: "0.000 SOL",
    // },
    // {
    //   name: "XRP",
    //   fullname: `XRPUSDT`,
    //   price: "0.000 ",
    //   info: "0.000 XRP",
    // },
  ];
  const [tableData, setTableData] = useState(coinData);

  const jwt = localStorage.getItem("jwt");

  const view_totp = async () => {
    const postData = { jwt: jwt, project: "FinGenius" };
    axios({
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH_API + "api_list",
      data: postData,
    })
      .then(async function (resp) {
        const response = resp.data;
        const res = resp.data.Success;
        setapilistdata(response.Success);
        const parameters = response.Success.map((item) => ({
          exchange: item.Exchange,
          exchange_type: item.Exchange_Type,
        }));
        if (res) {
          for (let i = 0; i < res.length; i++) {
            if (
              res[i].Type === "PAPER" &&
              res[i].Exchange_Type === "SPOT" &&
              res[i].Status === "ACTIVE"
            ) {
              setSelectedAPI(res[i]?.API_Name);
              setSelectedBalance(
                paperAPIs && res[i]?.Balance ? res[i]?.Balance : {}
              );
              setUSDTonload(
                paperAPIs && res[i]?.Balance.USDT ? res[i]?.Balance.USDT : {}
              );
              if (res[i]?.Type === "SPOT") {
                setFilter(res[i]?.Exchange_Type);
              }
              let sum = 0;
              Object.entries(
                paperAPIs && res[i]?.Balance ? res[i]?.Balance : {}
              )?.forEach(([coin, quantity]) => {
                const coinData = tableData?.find((item) => item?.name === coin);
                if (coinData && coinData?.price) {
                  const value =
                    parseFloat(quantity) * parseFloat(coinData.price);
                  sum += value;
                  setTotalValue(sum);
                }
              });
            }
          }
        }

        // handleButtonClick(response.Success[0].API_Name)
      })
      .catch((err) => { });
  };

  useEffect(() => {
    view_totp();
  }, [tableData]);

  // const liveAPIs =
  //   apilistdata &&
  //   apilistdata?.filter((d) => d?.Type === "LIVE" && d?.Status === "ACTIVE");
  // const paperAPIs =
  //   apilistdata &&
  //   apilistdata?.filter((d) => d?.Type === "PAPER" && d?.Status === "ACTIVE");

  const liveAPIs =
    Array.isArray(apilistdata) &&
    apilistdata.filter((d) => d?.Type === "LIVE" && d?.Status === "ACTIVE");
  const paperAPIs =
    Array.isArray(apilistdata) &&
    apilistdata.filter((d) => d?.Type === "PAPER" && d?.Status === "ACTIVE");


  const exchangeImageMapping = {
    BINANCEFUTURES: { img: img111, type: "FUTURES" },
    BINANCESPOT: { img: img211, type: "SPOT" },
    KUCOINSPOT: { img: img411, type: "SPOT" },
    KUCOINFUTURES: { img: img311, type: "FUTURES" },
    BYBITFUTURES: { img: img511, type: "FUTURES" },
    BYBITSPOT: { img: img611, type: "SPOT" },
  };

  const exchangeImageMapping1 = {
    USDT: img,
    ADA: img2,
    BNB: img3,
    BTC: img4,
    DOGE: img5,
    ETH: img6,
    GALA: img7,
    MANA: img8,
    OCEAN: img9,
    SOL: img10,
    XRP: img11,
    USDC: img12,
    FDUSD: img13,
  };
  const buttonRef = useRef(null);

  const calculateprice = () => {
    if (selectedBalance != {}) {
      let sum = 0;
      Object.entries(selectedBalance)?.forEach(([coin, quantity]) => {
        const coinData = tableData?.find((item) => item?.name === coin);
        if (coinData && coinData?.price) {
          const value = parseFloat(quantity) * parseFloat(coinData.price);
          sum += value;
          setTotalValue((sum += value));
        }
      });
      setOpen(false);

      setPercentage(
        ((selectedBalance[selectedOption] *
          tableData.find((item) => item?.fullname === selectedOption)?.price ||
          0) /
          sum) *
        100
      );
    }
  };
  const handleButtonClick = (api) => {
    setUSDTonload(api.Balance.USDT);
    let sum = 0;
    console.log(tableData, "tableData");
    console.log(api.Balance, "api.Balance");
    // Object.entries(selectedBalance)?.forEach(([coin, quantity]) => {
    //   const coinData = tableData?.find((item) => item?.name === coin);
    //   if (coinData?.price) {
    //     const value = parseFloat(quantity) * parseFloat(coinData.price);
    //     setTotalValue(sum+=value);
    //     console.log(sum+=value)
    //   }
    // });
    // setOpen(false);
    // setPercentage(
    //   ((selectedBalance[selectedOption] *
    //     tableData.find((item) => item?.fullname === selectedOption)
    //       ?.price || 0) /
    //     sum) *
    //   100
    // );

    Object.entries(api.Balance)?.forEach(([coin, quantity]) => {
      const coinData = tableData?.find((item) => item?.name === coin);
      if (coinData?.price) {
        const value = parseFloat(quantity) * parseFloat(coinData.price);
        sum += value;
        console.log(sum, "sum");
      }
    });

    // Update totalValue and percentage
    setTotalValue(sum);
    setPercentage(
      ((api.Balance[api.API_Name] *
        tableData.find((item) => item?.fullname === api.API_Name)?.price || 0) /
        sum) *
      100
    );
    setSelectedAPI(api.API_Name);
    setSelectedBalance(api ? api.Balance : {});
    if (api?.Type === "SPOT" || api?.Type === "LIVE") {
      setFilter(api?.Exchange_Type);
    }
  };
  const handlefilter = (filter) => {
    setFilter(filter);
    if (filter == "SPOT") {
      const socket = new WebSocket(
        `wss://stream.binance.com:9443/ws/!ticker@arr`
      );
      socket.onmessage = (event) => {
        const tickers = JSON.parse(event.data);
        if (tableData) {
          const updatedTableData = tableData.map((coin) => {
            const ticker = tickers.find((t) => t.s === coin?.fullname);
            if (ticker) {
              setReceivedCoins(
                (prevCoins) => new Set(prevCoins?.add(coin.name))
              );
              return { ...coin, price: ticker.c };
            }
            return coin;
          });
          setTableData(updatedTableData);
        }
        if (tableData[0].price != 0.0) {
          socket.close();
        }
        if (receivedCoins.size === coinData.length) {
          socket.close();
        }
      };
      return () => {
        socket.close();
      };
    } else {
      const socket = new WebSocket(`wss://fstream.binance.com/ws/!ticker@arr`);
      socket.onmessage = (event) => {
        const tickers = JSON.parse(event.data);

        if (tableData) {
          const updatedTableData = tableData.map((coin) => {
            const ticker = tickers.find((t) => t.s === coin.fullname);
            if (ticker) {
              setReceivedCoins(
                (prevCoins) => new Set(prevCoins.add(coin.name))
              );
              return { ...coin, price: ticker.c };
            }
            return coin;
          });
          setTableData(updatedTableData);
        }
        if (tableData[0].price != 0.0) {
          socket.close();
        }
        if (receivedCoins.size === coinData.length) {
          socket.close();
        }
      };
      return () => {
        socket.close();
      };
    }
  };
  const options = ["USDT", "BNB", "ETH", "BTC"];
  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    if (filter == "SPOT") {
      const socket = new WebSocket(
        `wss://stream.binance.com:9443/ws/!ticker@arr`
      );
      socket.onmessage = (event) => {
        const tickers = JSON.parse(event.data);
        if (tableData[0].price == 0.0) {
          const updatedTableData = tableData.map((coin) => {
            const ticker = tickers.find((t) => t.s === coin.fullname);
            if (ticker) {
              setReceivedCoins(
                (prevCoins) => new Set(prevCoins.add(coin.name))
              );
              return { ...coin, price: ticker.c };
            }
            return coin;
          });
          setTableData(updatedTableData);
        }
        if (tableData[0].price != 0.0) {
          socket.close();
        }
        if (receivedCoins.size === coinData.length) {
          socket.close();
        }
      };
      return () => {
        socket.close();
      };
    } else {
      const socket = new WebSocket(
        `wss://fstream.binance.com:9443/ws/!ticker@arr`
      );
      socket.onmessage = (event) => {
        const tickers = JSON.parse(event.data);
        if (tableData[0].price == 0.0) {
          const updatedTableData = tableData.map((coin) => {
            const ticker = tickers.find((t) => t.s === coin.fullname);
            if (ticker) {
              setReceivedCoins(
                (prevCoins) => new Set(prevCoins.add(coin.name))
              );
              return { ...coin, price: ticker.c };
            }
            return coin;
          });
          setTableData(updatedTableData);
        }
        if (tableData[0].price != 0.0) {
          socket.close();
        }
        if (receivedCoins.size === coinData.length) {
          socket.close();
        }
      };
      return () => {
        socket.close();
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receivedCoins, tableData]);
  useEffect(() => {
    // handleButtonClick("test")
    calculateprice();
  }, []);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const [isRefreshEnabled, setIsRefreshEnabled] = useState(true);
  const [remainingTime, setRemainingTime] = useState(0);

  const [isCursorDisabled, setIsCursorDisabled] = useState(false);

  // const view_refresh = async () => {
  //   if (isRefreshEnabled) {
  //     setIsRefreshing(true);
  //     setIsRefreshEnabled(false);
  //     setIsCursorDisabled(true); // Disable cursor when refreshing

  //     const postData = { jwt: jwt, project: 'FinGenius' };

  //     axios({
  //       method: 'POST',
  //       headers: {
  //         Authorization: `Bearer ${jwt}`,
  //         'Content-Type': 'application/json',
  //       },
  //       url: process.env.REACT_APP_API_PATH_API + 'view_bal',
  //       data: postData,
  //     })
  //       .then(async function (resp) {
  //         const res = resp.data.Success;
  //         if (res) {
  //           setIsRefreshing(false);
  //           view_totp();
  //           setapilistdata(res);

  //           // Enable refresh after 10 minutes
  //           setTimeout(() => {
  //             setIsRefreshEnabled(true);
  //             setIsCursorDisabled(false); // Enable cursor when timer is finished
  //           }, 10 * 60 * 1000);

  //           // Start a timer to update the remaining time every second
  //           let seconds = 10 * 60;
  //           const intervalId = setInterval(() => {
  //             setRemainingTime(seconds);
  //             seconds -= 1;

  //             // Clear the interval when the timer reaches 0
  //             if (seconds < 0) {
  //               clearInterval(intervalId);
  //               setRemainingTime(0);
  //             }
  //           }, 1000);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err.response.data.Error);
  //         setIsRefreshing(false);
  //         setIsRefreshEnabled(true);
  //         setIsCursorDisabled(false); // Enable cursor in case of an error
  //       });
  //   } else {
  //     console.log('View refresh restricted. Please wait.');
  //   }
  // };

  // const formatTime = (seconds) => {
  //   const minutes = Math.floor(seconds / 60);
  //   const remainingSeconds = seconds % 60;
  //   return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  // };

  const view_refresh = async () => {
    if (isRefreshEnabled) {
      setIsRefreshing(true);
      setIsRefreshEnabled(false);
      setIsCursorDisabled(true);

      const postData = { jwt: jwt, project: "FinGenius" };

      axios({
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Content-Type": "application/json",
        },
        url: process.env.REACT_APP_API_PATH_API + "view_bal",
        data: postData,
      })
        .then(async function (resp) {
          const res = resp.data.Success;
          if (res) {
            setIsRefreshing(false);
            setapilistdata(res);

            // Enable refresh after 10 minutes
            setTimeout(() => {
              setIsRefreshEnabled(true);
              setIsCursorDisabled(false);
            }, 10 * 60 * 1000);

            // Start a timer to update the remaining time every second
            const startTime = new Date().getTime();
            localStorage.setItem("timerStartTime", startTime.toString());

            const intervalId = setInterval(() => {
              const currentTime = new Date().getTime();
              const elapsedSeconds = Math.floor(
                (currentTime - startTime) / 1000
              );
              const remainingSeconds = Math.max(10 * 60 - elapsedSeconds, 0);

              setRemainingTime(remainingSeconds);

              // Clear the interval when the timer reaches 0
              if (remainingSeconds === 0) {
                clearInterval(intervalId);
                localStorage.removeItem("timerStartTime");
              }
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err.response.data.Error);
          setIsRefreshing(false);
          setIsRefreshEnabled(true);
          setIsCursorDisabled(false);
        });
    } else {
      console.log("View refresh restricted. Please wait.");
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  useEffect(() => {
    // Check if there is a timer running on page load
    const startTime = localStorage.getItem("timerStartTime");
    if (startTime) {
      const currentTime = new Date().getTime();
      const elapsedSeconds = Math.floor(
        (currentTime - parseInt(startTime)) / 1000
      );
      const remainingSeconds = Math.max(10 * 60 - elapsedSeconds, 0);

      setRemainingTime(remainingSeconds);

      if (remainingSeconds > 0) {
        // Start a new timer based on the remaining time
        const intervalId = setInterval(() => {
          const currentTime = new Date().getTime();
          const elapsedSeconds = Math.floor(
            (currentTime - parseInt(startTime)) / 1000
          );
          const remainingSeconds = Math.max(10 * 60 - elapsedSeconds, 0);

          setRemainingTime(remainingSeconds);

          // Clear the interval when the timer reaches 0
          if (remainingSeconds === 0) {
            clearInterval(intervalId);
            localStorage.removeItem("timerStartTime");
          }
        }, 1000);
      } else {
        // The timer has already expired, clear the stored start time
        localStorage.removeItem("timerStartTime");
      }
    }
  }, []);

  return (
    <div className="container">

      <div className="mx-auto p-0 rounded-lg">
        <div className="xl:flex w-full gap-2 justify-between h-full">
          <div className=" xl:w-10/12 w-full bg-colorPrimary   p-3 px-2 ">
            <div className="flex_reverse">
              <div>
                <h4 className="lg:text-2xl text-md border-b border-dashed border-colorSecondaryDark md:text-left text-center text-colorSecondaryDark font-bold">
                  Margin Balance
                </h4>
                <div className="flex items-center justify-start gap-3">
                  <div className="flex items-center justify-start gap-3">
                    <div className="md:text-3xl text-lg text-colorSecondaryDark mt-2 border border-dashed border-b-2 border-r-0 border-l-0 border-t-0 w-fit">
                      {totalValue ? totalValue.toFixed(2) : "0.00000"}
                    </div>
                    <select
                      id="selectOption"
                      className="bg-colorPrimary shadow-neumorphicnew mt-1 px-2  rounded-md"
                      onChange={(event) =>
                        handleOptionChange(event.target.value)
                      }
                      value={selectedOption}
                    >
                      <option value="USDT">USDT</option>
                    </select>
                    <div
                      className="bg-colorAccent hidden lg:block  px-2 py-1 text-sm cursor-pointer rounded-md text-colorPrimary text-center w-fit hover:bg-colorAccent/95 xl:hidden "
                      onClick={showDrawer}
                    >
                      Select API
                    </div>
                  </div>
                </div>

                <div className="flex my-5 justify-between gap-6 items-center">
                  <div>
                    <h4 className="lg:text-xs text-xs  md:text-left text-center text-colorSecondaryDark font-bold">
                      Wallet Balance(USD)
                    </h4>
                    <p className="text-xs text-colorSecondaryDark">1,966.0588</p>
                  </div>
                  <div >
                    <h4 className="lg:text-xs text-xs border-b border-dashed border-colorSecondaryDark  md:text-left text-center text-colorSecondaryDark font-bold">
                      Unrealized PNL(USD)
                    </h4>
                    <p className="text-xs text-colorSecondaryDark">-379.9599</p>
                  </div>
                </div>
              </div>
              <div className=" lg:text-2xl xl:text-3xl text-colorAccent mt-2 border border-dashed border-b-2 border-r-0 border-l-0 border-t-0 w-fit">
                {selectedAPI}
              </div>
              <div>
                {/* <div className="p-4">
                  <div className="flex items-center justify-start">
                    <button
                      onClick={() => handlefilter("SPOT")}
                      className={`mr-2 px-4 py-1 rounded-full w-28 shadow-md ${filter === "SPOT"
                        ? "bg-colorAccent text-white border-2 border-white drop-shadow-xl"
                        : "bg-white text-colorAccent"
                        }`}
                    >
                      Spot
                    </button>
                    <button
                      onClick={() => handlefilter("FUTURES")}
                      className={`px-4 py-1 rounded-full w-28 shadow-md ${filter === "FUTURES"
                        ? "bg-colorAccent text-white border-2 border-white drop-shadow-xl"
                        : "bg-white text-colorAccent"
                        }`}
                    >
                      Future
                    </button>
                  </div>
                </div> */}
              </div>
            </div>
            {/* </div> */}
            <div className="flex items-center gap-3">
              <h2 hidden>Estimated Balance</h2>{" "}
              <Select
                hidden
                defaultValue="lucy"
                options={[
                  {
                    value: "jack",
                    label: "Jack",
                  },
                  {
                    value: "lucy",
                    label: "Lucy",
                  },
                  {
                    value: "Yiminghe",
                    label: "yiminghe",
                  },
                  {
                    value: "disabled",
                    label: "Disabled",
                    disabled: true,
                  },
                ]}
              />
            </div>

            {/* Desktop...........*****************         */}

            <div class="hidden  lg:grid md:grid-cols-3 xl:grid-cols-4 grid-cols-1 gap-3 mt-3 h-[65%]">
              <div className="col-span-1 row-span-6 flex justify-center flex-col h-full items-center bg-colorPrimary shadow-lg shadow-neumorphic rounded-2xl">
                <div>
                  <img
                    src={img}
                    alt="coin"
                    className="w-36 rounded-full shadow-black"
                  />
                </div>

                <div>
                  <div className="text-center">
                    <div className="font-bold">USDT</div>
                    <div className="text-xs italic font-semibold">
                      $ {USDTonload ? USDTonload : "0.00"}USDT
                    </div>
                    <div>
                      $ {tableData[0].price ? tableData[0].price : "0.00"}
                    </div>
                  </div>
                </div>
              </div>
              {tableData?.slice(1).map((coin, index) => {
                const initialBalance = selectedBalance[coin?.name] || "0";
                return (
                  <div
                    key={index}
                    className="bg-colorPrimary h-20 shadow-md p-3 rounded-2xl relative shadow-neumorphic"
                  >
                    <div className="flex justify-between items-center gap-2">
                      <img
                        src={exchangeImageMapping1[coin?.name]}
                        alt="coin"
                        className="md:w-14 rounded-full shadow-black"
                      />

                      {/* <div className="hidden md:flex flex-col justify-start items-start w-full"> */}
                      <div className="hidden md:flex flex-col justify-start items-start w-full">
                        <div className="font-bold">{coin?.name}</div>
                        <div className="flex-shrink-0 text-xs italic font-semibold">
                          {initialBalance !== "0"
                            ? `${initialBalance} ${coin?.name}`
                            : `0 ${coin?.name}`}
                        </div>
                      </div>
                      <div className="hidden md:block">
                        ${(coin.price * initialBalance).toFixed(4)}
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                );
              })}
            </div>

            {/* MobileView...........*****************         */}

            <div class="lg:hidden grid xl:grid-cols-4 grid-cols-1 gap-3 mt-3 h-[65%]">
              <div className="grid grid-rows-12">
                <div className="flex justify-between items-center mb-2">
                  <div className="text-lg text-colorAccent mt-2 border border-dashed border-b-2 border-r-0 border-l-0 border-t-0 w-fit">
                    {selectedAPI}
                  </div>
                  <div
                    className="bg-colorAccent  px-2 py-1 text-sm cursor-pointer rounded-md text-colorPrimary text-center w-fit hover:bg-colorAccent/95 xl:hidden block"
                    onClick={showDrawer}
                  >
                    Select API
                  </div>
                </div>
                {selectedAPI?.Balance?.USDT ? (
                  <div className="w-full flex justify-between px-3 py-1 h-full  items-center bg-colorPrimary shadow-lg shadow-neumorphic rounded-2xl">
                    <div className="flex justify-start">
                      <img
                        src={img}
                        alt="coin"
                        className="w-14 rounded-full shadow-black"
                      />
                    </div>

                    <div className="text-center flex flex-col justify-end items-end">
                      <div className="font-bold">USDT</div>
                      <div>${selectedAPI?.Balance?.USDT}</div>

                      <div className="text-xs italic font-semibold">
                        ${selectedAPI?.Balance?.USDT} USDT
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="w-full flex justify-between px-3 py-1 h-full items-center bg-colorPrimary shadow-lg shadow-neumorphic rounded-2xl">
                    <div className="flex justify-start items-start">
                      <img
                        src={img}
                        alt="coin"
                        className="w-14 rounded-full shadow-black"
                      />
                    </div>
                    <div className="text-center flex items-end justify-end flex-col">
                      <div className="font-bold">USDT</div>
                      <div className="text-xs italic font-semibold">
                        $ {USDTonload ? USDTonload : "0.00"}USDT
                      </div>
                      <div>
                        $ {tableData[0].price ? tableData[0].price : "0.00"}
                      </div>
                      {/* <div>$0</div>

                      <div className="text-xs italic font-semibold">
                        $0 USDT
                      </div> */}
                    </div>
                  </div>
                )}
              </div>
              <div className="grid  grid-cols-2 gap-3">
                {tableData?.slice(1).map((coin, index) => {
                  const initialBalance = selectedBalance[coin?.name] || "0";
                  return (
                    <div>
                      {/* <div className="flex"> */}
                      <div
                        key={index}
                        className="bg-colorPrimary h-20 shadow-md p-2 rounded-2xl relative shadow-neumorphic"
                      >
                        <div className="justify-center items-center text-center ">
                          <div className="flex justify-between items-center">
                            <img
                              src={exchangeImageMapping1[coin?.name]}
                              alt="coin"
                              className="w-10 rounded-full shadow-black"
                            />
                            <div className="flex flex-col">
                              <div className="font-bold text-end">
                                {coin?.name}
                              </div>

                              <div className="flex-shrink-0 text-xs italic font-semibold text-end">
                                {initialBalance !== "0"
                                  ? `${initialBalance} ${coin?.name}`
                                  : `0 ${coin?.name}`}
                              </div>
                              <div className="text-end text-sm">
                                ${(coin.price * initialBalance).toFixed(2)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <Drawer
            title="Select API"
            placement="left"
            onClose={onClose}
            open={open}
          >
            <div className="space-y-4 px-2">
              {liveAPIs && liveAPIs.length > 0 ? (
                <div className="bg-colorAccent/20 h-60 shadow-md p-2 rounded-md text-center shadow-neumorphic">
                  <div className="mb-2">Live Api</div>
                  <div className="space-y-2">
                    {liveAPIs.map(
                      (d) =>
                        (filter === "all" || filter === d?.Exchange_Type) && (
                          <button
                            key={d?.APIID}
                            ref={buttonRef}
                            onClick={() => handleButtonClick(d)}
                            className="handleButtonClick block bg-colorAccent  w-full  text-dark text-center rounded-lg shadow-md px-2 py-2"
                          >
                            <div className="flex justify-evenly items-center">
                              <p>{d?.API_Name}</p>

                              <img
                                className="w-7"
                                src={
                                  exchangeImageMapping[
                                    d?.Exchange + d?.Exchange_Type
                                  ]?.img ||
                                  exchangeImageMapping[d?.Exchange_Type]?.img
                                }
                                alt={d?.Exchange}
                              />

                              <div className="text-sm">{d?.Exchange_Type}</div>
                            </div>
                          </button>
                        )
                    )}
                  </div>
                </div>
              ) : (
                <div className="bg-colorAccent/20 h-60 shadow-md p-2 rounded-md text-center shadow-neumorphic">
                  <div className="mb-2">No Live Api Available</div>
                  <div className="space-y-2">
                    <button className="block bg-colorAccent w-full  text-dark text-center rounded-lg shadow-md px-2 py-2">
                      Add LIVE API
                    </button>
                  </div>
                </div>
              )}
              {paperAPIs && paperAPIs.length > 0 ? (
                <div className="bg-colorSecondaryDark/20 h-64 shadow-md p-2 rounded-md text-center shadow-neumorphic">
                  <div className="mb-2">Paper Api</div>
                  <div className="space-y-2">
                    {paperAPIs.map(
                      (d) =>
                        (filter === "all" || filter === d?.Exchange_Type) && (
                          <button
                            key={d?.APIID}
                            ref={buttonRef}
                            onClick={() => handleButtonClick(d)}
                            className="handleButtonClick block w-full  bg-colorSecondaryDark text-white text-center rounded-lg shadow-md px-2 py-2"
                          >
                            <div className="flex justify-evenly items-center">
                              <p>{d?.API_Name}</p>

                              <img
                                className="w-7"
                                src={
                                  exchangeImageMapping[
                                    d?.Exchange + d?.Exchange_Type
                                  ]?.img ||
                                  exchangeImageMapping[d?.Exchange_Type]?.img
                                }
                                alt={d?.Exchange}
                              />

                              <div className="text-sm">{d?.Exchange_Type}</div>
                            </div>
                          </button>
                        )
                    )}
                  </div>
                </div>
              ) : (
                <div className="bg-colorSecondaryDark/20 h-64 shadow-md p-2 rounded-md text-center shadow-neumorphic">
                  <div className="mb-2">No PAPER Api Available</div>
                  <div className="space-y-2">
                    <button className="block w-full bg-colorSecondaryDark text-white text-center rounded-lg shadow-md px-2 py-2">
                      <div>Add PAPER API</div>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Drawer>

          <div className="xl:w-2/12 bg-colorPrimary p-3 rounded-lg xl:block hidden">
            <div className="space-y-4">
              {liveAPIs && liveAPIs.length > 0 ? (
                <div className="bg-colorAccent/20 h-60 shadow-md p-2 rounded-md text-center shadow-neumorphic">
                  <div className="mb-2">Live Api</div>
                  <div className="space-y-2">
                    {liveAPIs.map(
                      (d) =>
                        // Check if the API type matches the selected filter
                        (filter === "all" || filter === d?.Exchange_Type) && (
                          <button
                            key={d?.APIID}
                            ref={buttonRef}
                            onClick={() => handleButtonClick(d)}
                            className="handleButtonClick block bg-colorAccent  w-full  text-dark text-center rounded-lg shadow-md px-2 py-2"
                          >
                            <div className="flex justify-evenly items-center">
                              <p className="lg:text-sm xl:3xl">{d?.API_Name}</p>

                              <img
                                className="w-7"
                                src={
                                  exchangeImageMapping[
                                    d?.Exchange + d?.Exchange_Type
                                  ]?.img ||
                                  exchangeImageMapping[d?.Exchange_Type]?.img
                                }
                                alt={d?.Exchange}
                              />

                              <div className="lg:text-sm xl:3xl">
                                {d?.Exchange_Type}
                              </div>
                            </div>
                          </button>
                        )
                    )}
                    <div className="space-y-2">
                      <Link to="/dashboard/api_settings">
                        <button className="block bg-colorAccent w-full text-sm  text-dark text-center rounded-lg shadow-md px-2 py-2">
                          Add LIVE API
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="bg-colorAccent/20 h-60 shadow-md p-2 rounded-md text-center shadow-neumorphic">
                  <div className="mb-2">No Live Api Available</div>
                  <div className="space-y-2">
                    <button className="block bg-colorAccent w-full  text-dark text-center rounded-lg shadow-md px-2 py-2">
                      Add LIVE API
                    </button>
                  </div>
                </div>
              )}
              {paperAPIs && paperAPIs.length > 0 ? (
                <div className="bg-colorSecondaryDark/20 h-64 shadow-md p-2 rounded-md text-center shadow-neumorphic">
                  <div className="mb-2">Paper Api</div>
                  <div className="space-y-2">
                    {paperAPIs.map(
                      (d) =>
                        (filter === "all" || filter === d?.Exchange_Type) && (
                          <button
                            key={d?.APIID}
                            ref={buttonRef}
                            onClick={() => handleButtonClick(d)}
                            className="handleButtonClick block w-full  bg-colorSecondaryDark text-white text-center rounded-lg shadow-md px-2 py-2"
                          >
                            <div className="flex justify-evenly items-center">
                              <p className="lg:text-sm xl:3xl">{d?.API_Name}</p>

                              <img
                                className="w-7 lg:w-3 xl:w-7"
                                src={
                                  exchangeImageMapping[
                                    d?.Exchange + d?.Exchange_Type
                                  ]?.img ||
                                  exchangeImageMapping[d?.Exchange_Type]?.img
                                }
                                alt={d?.Exchange}
                              />

                              <div className="lg:text-xs xl:3xl">
                                {d?.Exchange_Type}
                              </div>
                            </div>
                          </button>
                        )
                    )}
                    <div className="space-y-2">
                      <Link to="/dashboard/api_settings">
                        <button className="block w-full text-sm bg-colorSecondaryDark text-white text-center rounded-lg shadow-md px-2 py-2">
                          <div>Add PAPER API</div>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="bg-colorSecondaryDark/20 h-64 shadow-md p-2 rounded-md text-center shadow-neumorphic">
                  <div className="mb-2">No PAPER Api Available</div>
                  <div className="space-y-2">
                    <button className="block w-full bg-colorSecondaryDark text-white text-center rounded-lg shadow-md px-2 py-2">
                      <div>Add PAPER API</div>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <Coinprice /> */}
      <Activebotnew />
    </div>
  );
};

export default Balance;
