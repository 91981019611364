import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { Link, Outlet, useLocation } from "react-router-dom";
import { BiSupport } from "react-icons/bi";

const Dashboard = ({ selectedImage }) => {
  const [open, setOpen] = useState(true);
  const location = useLocation();
  const [activeoption, setActiveoption] = useState(null);

  useEffect(() => {
    if (open == true) {
      setOpen(false);
    }
  }, []);

  const gettype = localStorage.getItem("setDefault");

  useEffect(() => {
    if (gettype === "normal" || gettype === "icon") {
      setOpen(false);
    }
  });

  const handleOptionclick = (option) => {
    setActiveoption(option);
    localStorage.setItem("setDefault", option);

    if (option === "menu") {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };
  const [isOpen, setIsOpen] = useState(true);

  const [openSide, setOpenSide] = useState(true);
  const toggleSidebar = () => {
    setIsOpen(!isOpen); 
    setSidebarOpen(!sidebarOpen);
  };

  const [sidebarOpen, setSidebarOpen] = useState(true);


   const pathToHideIcon = "/dashboard/support"; 
    const pathsToApplyBottom15 = [
      "/dashboard/api_settings",
      "/dashboard/support",
      
    ];

  return (
    <div>
      <div className=" lg:h-screen  bg-colorPrimary">
        {/* <Navbar
          openSide={openSide}
          setOpenSide={setOpenSide}
          selectedImage={selectedImage}
          className="z-50"
        /> */}
        <div
          className={` overflow-auto h-auto min-h-screen ${
            openSide
              ? "xl:ml-56 md:ml-56 md:mr-3 md:pt-5 bg-colorPrimary "
              : "md:pl-24 md:pr-4 lg:ml-0  md:pt-5 w-full"
          } `}
        >
          <div className={`  ${pathsToApplyBottom15.includes(window.location.pathname) ? '' : 'mb-[5%]'}`}>
            <Sidebar openSide={openSide} setOpenSide={setOpenSide} />
            <Outlet className={`bottom-12 ${pathsToApplyBottom15.includes(window.location.pathname) ? '' : 'mb-[5%]'}`} />
          </div>

          {/* <div className="mb-[5%] pt-14">
            <Sidebar openSide={openSide} setOpenSide={setOpenSide} />
            <Outlet className="mb-[10%] bottom-12" />
          </div> */}
          <div className="md:block hidden">
            <Link
              to="/dashboard/support"
              className={`bottom-10 right-0 z-40 fixed p-2 rounded-full shadow-neumorphic bg-colorAccent mx-2 ${
                location.pathname === pathToHideIcon ? "hidden" : ""
              } `}
            >
              <BiSupport size={25} className="text-white" />
            </Link>
          </div>
          <footer className="fixed bottom-0 ticker-container w-full text-sm z-30 bg-colorAccent text-white">
            <marquee behavior="scroll" direction="left" className="z-40 w-full">
              Information provided for reference only. Data sourced from third
              parties; accuracy not guaranteed. Not financial advice. Verify
              independently before decisions. Use at your own risk. Subject to
              change without notice
            </marquee>
          </footer>
        </div>  
      </div>
    </div>
  );
};

export default Dashboard;
