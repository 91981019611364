import React, { useState } from "react";
import {
  AiFillSignal,
  AiFillYoutube,
  AiFillWindows,
  AiFillSetting,
  AiFillRedditCircle,
  AiOutlineLogin,
} from "react-icons/ai";
import { BiLogOutCircle } from "react-icons/bi";
import { BsFillChatDotsFill, BsFillFuelPumpDieselFill } from "react-icons/bs";
import { Link, Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Drawer, Menu, message } from "antd";
import logo from "../assets/images/logo.png";
import logo1 from "../assets/images/short.png";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { MdCurrencyExchange } from "react-icons/md";
import { GiUnplugged } from "react-icons/gi";
import { PiPlugsFill } from "react-icons/pi";
import { BiSolidBookBookmark } from "react-icons/bi";

function Sidebar({ open, openSide }) {
  const [isOpen, setIsOpen] = useState(false);

  // let location = useLocation();
  const handleLogout = () => {
    console.log("hello logout");
    localStorage.clear();
    message.success("Logout Successfully.");
    window.location.href = "/";
  };

  const customCloseIcon = <span style={{ color: "red" }}>X</span>;

  const location = useLocation();

  const onClose = () => {
    console.log("Closing something...");
    setIsOpen(false);
  };

  const [openSubMenu, setOpenSubMenu] = useState(null);

  const sidebarItems = [
    {
      title: "PANEL",
      path: "/dashboard/",
      icon: <AiFillWindows size={25} />,
    },
    {
      title: "API Credentials",
      icon: <PiPlugsFill size={25} />,
      subItems: [
        {
          title: "Add API",

          // icon: <AiFillRedditCircle size={25} />,
          path: "/dashboard/api_settings",
        },
        { title: "View API SPOT", path: "/dashboard/balance" },
      ],
    },
    {
      title: "BOTS",

      icon: <AiFillRedditCircle size={25} />,
      subItems: [
        {
          title: "Bots",

          // icon: <AiFillRedditCircle size={25} />,
          path: "/dashboard/botmain",
        },
        {
          title: "Active Bot",

          // icon: <AiFillRedditCircle size={25} />,
          path: "/dashboard/activebot",
        },
        {
          title: "Order Book",

          // icon: <AiFillRedditCircle size={25} />,
          path: "/dashboard/trades",
        },

        { title: "Reports", path: "/dashboard/report" },
      ],
    },
    {
      title: "Trades",
      path: "/dashboard/tradekeen",
      icon: <MdCurrencyExchange size={25} />,
    }, 
    {
      title: "Transactions",
      path: "/dashboard/transactiontable",
      icon: <MdCurrencyExchange size={25} />,
    },
    {
      title: "Subscriptions",
      path: "/dashboard/subscriptions",
      icon: <FaMoneyCheckAlt size={25} />,
    },
    {
      title: "Logs",
      path: "/dashboard/logs",
      icon: <AiOutlineLogin size={25} />,
    },
    {
      title: "Support",
      path: "/dashboard/support",
      icon: <BsFillChatDotsFill size={25} />,
    },
    // {
    //   title: "Fuel",
    //   path: "/dashboard/fuel",
    //   icon: <BsFillFuelPumpDieselFill size={25} />,
    // },
    {
      title: "Profile",
      path: "/dashboard/profile",
      icon: <AiFillSetting size={25} />,
    },
    {
      title: "Guide",
      path: "/dashboard/guide",
      icon: <BiSolidBookBookmark size={25} />,
    },
  ];

  

  const [submenuOpen, setSubmenuOpen] = useState(
    new Array(sidebarItems.length).fill(true)
  );

  const handleSubmenuClick = (index) => {
    const updatedSubmenuOpen = [...submenuOpen];
    updatedSubmenuOpen[index] = !updatedSubmenuOpen[index];
    setSubmenuOpen(updatedSubmenuOpen);
  };

  

  return (
    <>
      <div
        className={`md:flex  md:flex-col flex-row fixed left-0 right-0 bottom-0   md:h-screen h-[75px] hidden md:bg-colorPrimary border-[#ccc] border-l-2 rounded-r-2xl  lg:mt-32  ${
          openSide
            ? "md:w-[224px] "
            : "md:w-16 flex items-center justify-center "
        }`}
      >
        
        <div className="sidebar-wrapper ">
        <div
              className={`hidden md:flex bg-colorSecondaryDark py-4 w-40 md:w-56 justify-center items-center text-black ${
                openSide ? "md:w-[220px]" : "md:w-[67px]"
              }`}
            >
              {openSide ? (
                <>
                  <div className=" flex justify-center items-center ">
                    <Link to="/dashboard">
                      <img src={logo} className=" w-36  ml-0 " alt="Logo" />
                      {/* <span className=" text-white text-sm">Beta</span> */}
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <img
                    src={logo1}
                    className={` w-10  ml-0 ${!openSide && ""}`}
                    alt="Logo"
                  />
                </>
              )}
            </div>
          <div className="sidebar text-white">
            <ul className="md:space-y-3 p-3 md:pl-0 border-[#ccc] border-opacity-70 border-r-2 md:my-3 text-sm md:h-screen h-[75px] md:block md:flex-col items-center justify-center cursor-pointer flex-row w-full gap-3 md:pr-2 md:mt-0">
              {sidebarItems.map((item, index) => (
                <li
                  key={index}
                  className={`menu-item font-semibold  ${
                    location.pathname === item.path
                      ? "active bg-colorAccent text-white shadow-lg  rounded-r-full py-3 "
                      : "text-colorSecondaryDark bg-transparent py-3"
                  }`}
                  // onClick={() => handleSubmenuClick(index)}
                  onClick={() => {
                    if (!item.subItems) {
                      handleSubmenuClick(index);
                    }
                  }}
                >
                  <Link
                    to={item.path}
                    target={
                      item.path === "/dashboard/guide" ? "_blank" : "_self"
                    }
                  >
                    <div
                      className={`items-center  flex w-full justify-center pl-5 
                       ${location.pathname === item.path ? `` : ""}`}
                      onClick={() => handleSubmenuClick(index)}
                    >
                      <div className="flex items-center justify-between  w-full">
                        <div className="flex items-center justify-evenly">
                          <span
                            className={`text-colorAccent text-2xl mr-3 ${
                              location.pathname === item.path
                                ? "text-colorSecondaryDark"
                                : ""
                            }`}
                          >
                            {item.icon}
                          </span>
                          <span
                            className={`uppercase item-title ${
                              !openSide && "hidden"
                            } origin-left duration-200`}
                            // className=" uppercase item-title"
                          >
                            {item.title}
                          </span>
                        </div>
                        <div>
                          {item.subItems && openSide && (
                            <span
                              className={`arrow-icon text-colorSecondaryDark ${
                                submenuOpen[index] ? "open" : ""
                              }`}
                            >
                              {submenuOpen[index] ? "\u25B2" : "\u25BC"}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </Link>
                  {item.subItems && openSide && submenuOpen[index] && (
                    <ul
                      className={`sub-menu ml-11 bg-colorPrimary/20   mt-3 border-l-2 border-t-0 border-r-0 border-b-0 border-dashed border-colorPrimary ${
                        submenuOpen[index] ? "open" : ""
                      }  transition-transform delay-100 duration-200 `}
                    >
                      {item.subItems.map((subItem, subIndex) => (
                        <Link
                          key={subIndex}
                          to={subItem.path}
                          className={`uppercase ${!openSide && "hidden"}`}
                        >
                          <li
                            key={subIndex}
                            className={`flex items-start justify-start pt-2 pb-2 hover:text-colorSecondaryDark hover:border-colorAccent  cursor-pointer ${
                              location.pathname === subItem.path
                                ? "bg-colorAccent text-colorSecondaryDark  rounded-r-full py-1"
                                : ""
                            }`}
                          >
                            <div className="flex items-center ">
                              <span
                                className={`item-icon text-colorPrimary mb-1 mr-3 ${
                                  location.pathname === subItem.path
                                    ? "text-colorSecondaryDark"
                                    : ""
                                }`}
                              >
                                {subItem.icon}
                              </span>
                              <span
                                className={`uppercase item-title ${
                                  !openSide && "hidden"
                                } origin-left duration-200`}
                              >
                                {subItem.title}
                              </span>
                            </div>
                          </li>
                        </Link>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
            <div
              className={`footer bottom-0 fixed flex  flex-col text-center  mt-auto w-52 ${
                !openSide && "bottom-5 -left-5"
              }`}
            >
              {openSide && (
                <footer className="text-center mx-auto block">
                  <p
                    className={`text-colorSecondaryDark text-xs text-center  origin-left duration-200`}
                  >
                    All right reserved <br></br>
                    <div className="text-md font-extrabold">
                      ©FinGenius 2023
                    </div>
                  </p>
                </footer>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
